.intro-photo img {
    height: max(20vw, 25vh);
    clip-path: circle(39.24% at 50% 39%);
}

.intro-photo {
    filter: drop-shadow(0px 0px 50px rgb(86, 86, 86));
    height: 55%;
}

.intro-text {
    font-size: min(4rem, 6vmin);
    padding-left: 1rem;
    padding-bottom: 1rem;
    margin-top: auto;
    margin-bottom: auto;
}

.subtitle {
    font-size: min(1.8rem, 3.5vmin);
    padding-left: 0.1rem;
}

.job-subtitle {
    padding-top: 0.5rem;
    padding-left: 0.3rem;
    font-size: min(1.2rem, 3vmin);
}

.nametag {
    font-weight: 200;
}

@media (min-width: 992px) {
    .introduction {
        margin-top: 10rem;
    }
    .intro-description {
        width: 33vw;
        font-size: 2.5vmin;
    }
}

@media (max-width: 991px) {
    .introduction {
        flex-direction: column;
        align-items: center;
        margin-top: 12vh;
    }

    .intro-description {
        margin-top: 1vh;
        width: 70vw;
    }
}


.intro-description a, .intro-description a:hover, .intro-description a:visited {
    color: var(--text-color) !important;
}


.main {
  /* text-align: center; */
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  height: 100vh;
  scroll-behavior: smooth;
}

.page {
  height: 100vh;
  scroll-snap-align: start;
}

.page-intro {
  background-color: rgb(240, 240, 255);
}

.page-developer {
  background-color: rgb(255, 217, 210);
}

.page-education {
  background-color: rgb(218, 240, 238);
}

.page-contests {
  background-color: rgb(35, 37, 40);
  color: var(--dark-text-color);
}

.page-end {
  background-color: rgb(240, 240, 255);
}

.bottom-arrow {
  font-size: 2rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 30px;
  --fa-animation-delay: 8s;
  --fa-animation-timing: 4s;
  cursor: pointer;
}

button.text {
  background:none;
  border:none;
  margin:0;
  padding:0;
  cursor: pointer;
  color: var(--text-color);
}
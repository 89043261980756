.header-navbar {
    height: 6rem;
    font-size: 2rem;
    color: var(--text-color);
    position: fixed;
    z-index: 10000;
    width: 100%;
}

.header-dark {
    color: var(--dark-text-color) !important;
}

.header-dark button.text {
    color: var(--dark-text-color) !important;
}


.navbar-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: max(1.5rem, 2vw);
}

.navbar-right i {
    font-size: max(1.5vmax, 1.5rem);
    color: var(--text-color);
}

.header-dark .navbar-right i {
    color: var(--dark-text-color);
}

.name-logo { 
    padding-left: min(6vw, 40px);
    font-size: max(2.3vmax, 1.7rem);
}

.name-logo span {
    margin: auto;
}

.links-logo {
    padding-right: min(8vw, 80px);
}